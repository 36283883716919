<!-- <section class="page-section" id='about'>
  <div class="pimg1" id="pimg1">
    <div class="ptext">
      <span class="border">
        Press Kit
      </span>
    </div>
  </div>
</section> -->

<section class="press-kit" id="about">
  <h1>Foul Play</h1>
  <h2>Press Kit</h2>
</section>

<div class="grid">
  <div class="grid-sizer"></div>
  <div class="grid-item grid-item--width1 grid-item--height2"></div>
  <div class="grid-item grid-item--width2 grid-item--height2"></div>
  <div class="grid-item grid-item--width3 grid-item--height3"></div>
  <div class="grid-item grid-item--width4 grid-item--height3"></div>
</div>
<div class="grid-alpha"></div>

<section class="facts">
  <div class="columns" style="flex: 0 0 520px">
    <div class="colum">
      <div class="facts ">
        <p>
          <span class="facts-titles">Developer:</span> Bandit Visual Games, USA
        </p>
        <p><span class="facts-titles">Release Date:</span> October 22, 2021</p>
        <p>
          <span class="facts-titles">Available On: </span>
          <a
            href="https://store.steampowered.com/app/1600930/Foul_Play__Yuri_Visual_Novel/"
            target="_blank"
            >Steam</a
          >,
          <a href="https://banditvisualgames.itch.io/foul-play" target="_blank"
            >Itch.io</a
          >
        </p>
        <p><span class="facts-titles">Price:</span> $13.99 USD</p>
        <p><span class="facts-titles">Platforms:</span> Windows and Linux</p>
        <p><span class="facts-titles">Language:</span> English</p>
        <p>
          <span class="facts-titles">Contact:</span> banditvisualgames@gmail.com
        </p>
        <p>
          <span class="facts-titles">Website:</span> www.banditvisualgames.com
        </p>
      </div>
    </div>
    <div class="column">
      <div class="facts">
        <h1 class="facts-titles">
          Story & Gameplay
        </h1>
        <p class="facts-description">
          Foul Play has a few central themes, such as the adult protagonist discovering she's
          gay, facing initial rejection after coming out to her mom, navigating
          through these new feelings, and discovering what she really wants out
          of life. She must examine what's truly important to her as she experiences 
          life-changing events throughout her journey as a detective. A lot of the story
          and dialogue is very personal to my girlfriend and I as we put a
          lot of our own (L)GBT experiences - good and bad - into the storylines
          to make it feel realistic and genuine to the female/female dating
          world.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="facts-about">
  <div class="columns">
    <div class="column">
      <h1>7 Hours</h1>
      <h2>Total Game Playtime</h2>
    </div>
    <div class="column">
      <h1>5 Endings</h1>
      <h2>Choice-Based Game</h2>
    </div>
    <div class="column">
      <h1>500+ Art Assets</h1>
      <h2>Original Art From Backgrounds, CG, Sprites, Music, Gui, And Icons</h2>
    </div>
  </div>
</section>

<section class="about-us">
    <h1>About Us</h1>
    <p>
      We are a (L)GBT couple with diverse backgrounds in programming, UI design,
      and digital art. We have always been drawn to the very limited
      representation of lesbian romance in media (has anyone else finished the
      new L Word because they were thirsty for WLW media content despite how
      annoying it was?). We love video games with gorgeous art and impactful
      story-telling and we're thrilled to be contributing to the WLW gaming
      community.
    </p>
</section>

<section class="page-section">
  <div class="pimg1" id="pimg1">
    <div class="ptext">
      <span class="border"> Screenshots </span>
    </div>
  </div>
</section>

<div class="grid">
  <div class="grid-sizer"></div>
  <div class="gallery-item g-1"></div>
  <div class="gallery-item g-2"></div>
  <div class="gallery-item g-3"></div>
  <div class="gallery-item g-4"></div>
  <div class="gallery-item g-5"></div>
</div>
