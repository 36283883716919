import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { gsap } from 'gsap';
import { Draggable } from "gsap/Draggable";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HomeService]
})
export class HomeComponent implements OnInit {
  

  constructor(private homeService: HomeService) { 
  }

  ngOnInit() {
	gsap.registerPlugin(Draggable, ScrollTrigger);
    this.homeService.homeFunction();
	this.initScrollTriggers();

  }

  initScrollTriggers() {
    gsap.to(".mc-filled-text, .mc-outline-text", {
			scrollTrigger:{
				trigger: ".mc-filled-text, .mc-outline-text", 
				start: "top bottom", 
				end: "bottom top", 
				scrub: 1
			},
			x: -200
		});

		gsap.to(".mc-image", {
			scrollTrigger:{
				trigger: ".mc-image",
				start: "top bottom", 
				end: "bottom top", 
				scrub: 1,
				markers: false
			},
			x: 250,

		});



    gsap.to(".w-filled-text, .w-outline-text", {
		scrollTrigger:{
			trigger: ".w-filled-text, .w-outline-text", 
			start: "top bottom", 
			end: "bottom top", 
			scrub: 1
		},
		x: 200
    });

    gsap.to(".w-image", {
        scrollTrigger:{
            trigger: ".w-image",
            start: "top bottom", 
            end: "bottom top", 
            scrub: 1,
            markers: false
        },
        x: -250,

    });

    gsap.to(".c-filled-text, .c-outline-text", {
			scrollTrigger:{
				trigger: ".c-filled-text, .c-outline-text", 
				start: "top bottom", 
				end: "bottom top", 
				scrub: 1
			},
			x: -200
		});

		gsap.to(".c-image", {
			scrollTrigger:{
				trigger: ".c-image",
				start: "top bottom", 
				end: "bottom top", 
				scrub: 1,
				markers: false
			},
			x: 250,

		});
  }

}
