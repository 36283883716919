import { Injectable } from '@angular/core';
import { gsap } from "gsap";
import Draggable from "gsap/Draggable";
import ScrollTrigger from "gsap/ScrollTrigger";

@Injectable()
export class HomeService {
    homeFunction() {

        window.addEventListener('scroll', function () {
            var depth, i, layer, layers, len, movement, topDistance, translate3d;
            topDistance = this.pageYOffset;
            layers = document.querySelectorAll<HTMLElement>("[data-type='parallax']");
            for (i = 0, len = layers.length; i < len; i++) 
            {
                layer = layers[i];
                depth = layer.getAttribute('data-depth');
                movement = -(topDistance * Number(depth));
                translate3d = 'translate3d(0, ' + movement + 'px, 0)';
                layer.style['-webkit-transform'] = translate3d;
                layer.style['-moz-transform'] = translate3d;
                layer.style['-ms-transform'] = translate3d;
                layer.style['-o-transform'] = translate3d;
                layer.style.transform = translate3d;
                                                            
            }
        }, false);


    }


}