<header [ngClass]="{'navbar-inverse': isScrolled}">
  <nav class="navbar navbar-default navbar-fixed-top" #navbarid id="nav">
    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#topNavbar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>

    <div class="">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/bandit_games_logo.png" alt="bandit visual games logo" />
      </a>
      <p id="logo"><a class="navbar-brand" href="#">
          Bandit Visual Games </a>
      </p>
    </div>
    <nav class="navigation" id="mainNav">
      <div class="collapse navbar-collapse" id="topNavbar">
        <ul class="nav navbar-nav navbar-right" id="listed">
          <li id='game-link' ><a routerLink="/home">HOME</a></li>
          <li id='about-link' ><a routerLink="/about">PRESS KIT & ABOUT</a></li>
          <li id='contact-link'><a href="/home#contact">CONTACT</a></li>
          <li id='steam-link' >
            <a class="nav-img-link" href="https://store.steampowered.com/app/1600930/Foul_Play__Yuri_Visual_Novel/" target="_blank">
              <img class="nav-img" src="../../assets/images/steam.png" alt="Button Select Steam">
            </a>
          </li>
          <li id='itch-link' >
            <a class="nav-img-link" href="https://banditvisualgames.itch.io/foul-play" target="_blank">
              <img class="nav-img" src="../../assets/images/itch-badge.png" alt="Button Select Available On Itch.io">
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right" id="listed-mobile">
          <li id='itch-link' data-toggle="collapse" data-target="#topNavbar"><a href="https://store.steampowered.com/app/1600930/Foul_Play__Yuri_Visual_Novel/" target="_blank">STEAM</a></li>
          <li id='itch-link' data-toggle="collapse" data-target="#topNavbar"><a href="https://banditvisualgames.itch.io/foul-play" target="_blank">ITCH.IO</a></li>
          <li id='game-link' data-toggle="collapse" data-target="#topNavbar"><a routerLink="/home">FOUL PLAY</a></li>
          <li id='about-link' data-toggle="collapse" data-target="#topNavbar"><a routerLink="/about">ABOUT</a></li>
          <li id='contact-link' data-toggle="collapse" data-target="#topNavbar"><a href="/home#contact">CONTACT</a></li>
        </ul>
      </div>
    </nav>
  </nav>
</header>